import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { orderBy } from "lodash";
import { TextField } from "@material-ui/core";
import moment from "moment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "react-s-alert";
import { getOrgUserList, deleteGroupById } from "../util/APIUtils";
import {
  fetchMailStatuses,
  fetchCalendarStatuses,
  fetchLatestActivity,
  fetchCallData
} from "../charts/ActivityService";
import MuiTable from "../components/Table/Table";
import headers from "./UsersHeaders";
import "./UsersStyles.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

const Users = ({ selectedGroup, loadUserAndGroups, currentUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDir, setSortDir] = useState("asc");
  const [sortColumn, setSortColumn] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    getUsersAndData();
  }, [selectedGroup]);

  const getUsersAndData = async () => {
    setLoading(true);
    const mergedData = [];
    const fetchUsers = await getOrgUserList(selectedGroup.groupId);
    const promises = await Promise.all([
      fetchMailStatuses(null, selectedGroup.groupId),
      fetchCalendarStatuses(null, selectedGroup.groupId),
      fetchCallData(false, null, selectedGroup.groupId),
      fetchLatestActivity(false, selectedGroup.groupId)
    ]);
    const mailData = promises[0];
    const calendarData = promises[1];
    const callData = promises[2];
    const latestActivity = promises[3];
    fetchUsers.forEach(user => {
      const emailStatus = makeMailData(mailData[user.userId]);
      const calendarStatus = makeCalendarData(calendarData[user.userId]);
      const lastActivity = makeLastActivity(latestActivity[user.userId]);
      const callsEnabled = makeCallData(callData.enabledPerUser[user.userId]);
      if (!user.expiredDate) {
        const newUser = {
          ...user,
          emailStatus,
          calendarStatus,
          lastActivity,
          callsEnabled
        };
        mergedData.push(newUser);
      }
    });
    setUsers(mergedData);

    setLoading(false);
  };

  const makeMailData = mailData => {
    let emailStatus = {};
    try {
      if (mailData) {
        if (!mailData.enabled) {
          emailStatus = "Off";
        } else {
          emailStatus = mailData.type
            ? (mailData.automatic ? "Automatic " : "Manual ") + mailData.type
            : "-";
        }
      } else {
        emailStatus = "Off";
      }
    } catch (e) {
      console.warn("Error fetching call data", e);
      emailStatus = "-";
      return emailStatus;
    }
    return emailStatus;
  };

  const makeCalendarData = calendarData => {
    let calendarStatus = {};
    try {
      if (calendarData) {
        if (!calendarData.enabled) {
          calendarStatus = "Off";
        } else {
          calendarStatus = calendarData.type
            ? (calendarData.automatic ? "Automatic " : "Manual ") + calendarData.type
            : "-";
        }
      } else {
        calendarStatus = "Off";
      }
    } catch (e) {
      console.warn("Error fetching call data", e);
      calendarStatus = "-";
      return calendarStatus;
    }
    return calendarStatus;
  };

  const makeLastActivity = latestActivity => {
    let lastActivity = {};
    if (latestActivity) {
      lastActivity = latestActivity;
    } else {
      lastActivity = "";
    }
    return lastActivity;
  };

  const makeCallData = callData => {
    let callsEnabled = {};
    if (callData) {
      callsEnabled = "On";
    } else {
      callsEnabled = "Off";
    }
    return callsEnabled;
  };

  const handleSort = column => {
    const inverDir = {
      asc: "desc",
      desc: "asc"
    };
    setSortColumn(column);
    setSortDir(sortColumn === column ? inverDir[sortDir] : "asc");
  };

  const handleSearch = event => {
    let filteredDatas = [];
    filteredDatas = users.filter(value => {
      return (
        value.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        value.platform.toLowerCase().includes(event.target.value.toLowerCase()) ||
        moment(value.firstInstallation)
          .local()
          .format("L LTS")
          .includes(event.target.value) ||
        value.emailStatus.toLowerCase().includes(event.target.value.toLowerCase()) ||
        value.calendarStatus.toLowerCase().includes(event.target.value.toLowerCase()) ||
        moment(value.lastActivity)
          .local()
          .format("L LTS")
          .includes(event.target.value.toLowerCase())
      );
    });
    setFilteredData(filteredDatas);
    setSearchTerm(event.target.value);
  };

  const deleteGroup = async group => {
    try {
      await deleteGroupById(group.groupId);
      Alert.success(`Group: ${group.name} deleted successfully!`);
      loadUserAndGroups();
    } catch (error) {
      Alert.error("Deleting the group failed, please try again!");
    }
  };

  const renderLoader = () => {
    return (
      <div className="loader">
        <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
      </div>
    );
  };
  return (
    <div className="users-container">
      <div className="users-content">
        <div>
          <div className="header">
            <h1>Users for: {selectedGroup.name}</h1>
            {currentUser && currentUser.licenses ? (
              <NavLink to="/users/licenses" className="create_link">
                <div className="header_icon">
                  <HowToRegIcon />
                  Manage licenses
                </div>
              </NavLink>
            ) : null}
            {selectedGroup.groupId ? (
              <div className="header_icon" onClick={() => deleteGroup(selectedGroup)}>
                <HighlightOffIcon />
                <p>Delete group</p>
              </div>
            ) : null}
            <NavLink to="/users/create" className="create_link">
              <div className="header_icon">
                <AddCircleOutlineIcon />
                Create a group
              </div>
            </NavLink>
          </div>

          {loading ? (
            renderLoader()
          ) : (
            <div>
              <TextField
                id="outlined-search"
                label="Search..."
                type="search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{ paddingBottom: 5, width: 300, color: "#e33f85" }}
              />
              <MuiTable
                headers={headers}
                data={orderBy(searchTerm !== "" ? filteredData : users, sortColumn, sortDir)}
                handleSort={handleSort}
                sortDir={sortDir}
                sortColumn={sortColumn}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  loadUserAndGroups: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default Users;
