import React from "react";
import PropTypes from "prop-types";

const Button = ({ className, text, onClick, type, disabled }) => {
  return (
    <button onClick={onClick} className={className} type={type || "submit"} disabled={disabled}>
      {text}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["button", "submit"]).isRequired,
  disabled: PropTypes.bool
};

export default Button;
