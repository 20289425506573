import React, { useEffect } from "react";
import "./Login.css";
import { Redirect } from "react-router-dom";
import Alert from "react-s-alert";
import { PropTypes } from "prop-types";
import SalesforceLoginScreen from "./SalesforceLoginScreen";

const Login = ({ location, authenticated, history, loading }) => {
  useEffect(() => {
    // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
    // Here we display the error and then remove the error query parameter from the location.
    if (location.state && location.state.error) {
      setTimeout(() => {
        Alert.error(location.state.error, {
          timeout: 5000
        });
        history.replace({
          pathname: location.pathname,
          state: {}
        });
      }, 100);
    }
  }, [history, location.pathname, location.state]);
  if (!authenticated && loading) {
    return null;
  }
  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  }
  return <SalesforceLoginScreen />;
};

Login.propTypes = {
  location: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  history: PropTypes.object
};

export default Login;
