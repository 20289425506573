const headers = [
  {
    id: "name",
    label: "Name"
  },
  {
    id: "email",
    label: "Email"
  },
  {
    id: "appInfo",
    label: "App info"
  },
  {
    id: "lastActivity",
    label: "Last Activity",
    type: "date"
  },
  {
    id: "callsUnlogged",
    label: "Unlogged calls",
    align: "center"
  },
  {
    id: "callsLogged",
    label: "Logged calls",
    align: "center"
  },
  {
    id: "mailsUnlogged",
    label: "Unlogged emails",
    align: "center"
  },
  {
    id: "mailsLogged",
    label: "Logged emails"
  },
  {
    id: "calendarsUnlogged",
    label: "Unlogged events"
  },
  {
    id: "calendarsLogged",
    label: "Logged events"
  },
  {
    id: "bcardData",
    label: "Business cards"
  },
  {
    id: "expiredDate",
    label: "Expired",
    type: "date"
  },
  {
    id: "activity",
    label: "Activity",
    type: "date"
  },
  {
    id: "activityUser",
    label: "Activity user"
  }
];

export default headers;
