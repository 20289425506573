import React, { Component } from "react";
import "../Charts.css";
import moment from "moment";
import Loader from "react-loader-spinner";
import { orderBy } from "lodash";
import FilterTextField from "../../components/FilterTextField";
import Button from "../../components/Button";
import { getOrgUserList } from "../../util/APIUtils.js";
import MuiTable from "../../components/Table/Table";
import headers from "./ResponseTimeHeaders";
import { fetchResponseTimes } from "../ActivityService.js";

class ResponseTimeChart extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.loadUsersAndData = this.loadUsersAndData.bind(this);
    this.loadResponseTimeData = this.loadResponseTimeData.bind(this);

    this.state = {
      users: [],
      startDate: moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: true,
      sortDir: "asc",
      sortColumn: "name",
      selectedGroup: this.props.selectedGroup
    };
  }

  componentDidMount() {
    const { startDate, endDate, selectedGroup } = this.state;
    this.loadUsersAndData(startDate, endDate, selectedGroup.groupId);
  }

  componentDidUpdate = async prevProps => {
    if (prevProps.selectedGroup !== this.props.selectedGroup) {
      await this.setState({
        selectedGroup: this.props.selectedGroup,
        loading: true
      });
      this.loadUsersAndData(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedGroup.groupId
      );
    }
  };

  loadUsersAndData = async (from, to, groupId) => {
    const filters = {
      from: moment(from)
        .startOf("day")
        .toISOString(),
      to: moment(to)
        .endOf("day")
        .toISOString()
    };
    const users = await getOrgUserList(groupId);
    const filterExpired = users.filter(user => !user.expiredDate);
    this.setState({ users: filterExpired });
    this.loadResponseTimeData(filters, groupId);
    this.setState({
      loading: false
    });
  };

  loadResponseTimeData = async (filters, groupId) => {
    const { users } = this.state;
    const newUsers = [];
    try {
      const callData = await fetchResponseTimes(false, filters, groupId);
      if (!callData) {
        return;
      }
      for (const user of users) {
        const userData = callData[user.userId];
        if (!userData) {
          user.responseTimeCount = 0;
          user.responseTimeAverage = 0;
          user.responseTimeMinimum = 0;
          user.responseTimeMaximum = 0;
        } else {
          user.responseTimeCount = userData.count;
          user.responseTimeAverage = userData.average;
          user.responseTimeMinimum = userData.minimum;
          user.responseTimeMaximum = userData.maximum;
        }

        newUsers.push(user);
      }
      this.setState({ users });
    } catch (e) {
      console.warn("Error fetching call data", e);
      for (const user of users) {
        user.responseTimeCount = "-";
        user.responseTimeAverage = "-";
        user.responseTimeMinimum = "-";
        user.responseTimeMaximum = "-";
        newUsers.push(user);
      }
    }
    this.setState({ users: newUsers });
  };

  changeDate = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  filterResults = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    const { startDate, endDate, selectedGroup } = this.state;
    this.loadUsersAndData(startDate, endDate, selectedGroup.groupId);
  };

  handleSort = column => {
    const inverDir = {
      asc: "desc",
      desc: "asc"
    };

    this.setState({
      sortColumn: column,
      sortDir: this.state.sortColumn === column ? inverDir[this.state.sortDir] : "asc"
    });
  };

  renderLoader = () => {
    return (
      <div className="loader">
        <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
      </div>
    );
  };

  render() {
    const { users, loading, sortColumn, sortDir } = this.state;
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={this.filterResults}>
          <h3>Filter activity by dates</h3>
          <FilterTextField
            label="Start date"
            id="startDate"
            onChange={this.changeDate}
            value={this.state.startDate}
          />{" "}
          <FilterTextField
            label="End date"
            id="endDate"
            onChange={this.changeDate}
            value={this.state.endDate}
          />
          <Button
            className="form-button"
            text="Filter"
            onClick={this.filterResults}
            type="submit"
            disabled={this.state.loading}
          />
        </form>
        {loading ? (
          this.renderLoader()
        ) : (
          <MuiTable
            headers={headers}
            data={orderBy(users, sortColumn, sortDir)}
            handleSort={this.handleSort}
            sortDir={sortDir}
            sortColumn={sortColumn}
          />
        )}
      </>
    );
  }
}

export default ResponseTimeChart;
