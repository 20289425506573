import React from "react";
import PropTypes from "prop-types";
import MuiTable from "../../../../components/Table/Table";
import headers from "./OrganizationListHeaders";

const OrganizationList = ({
  handleSort,
  sortDir,
  sortColumn,
  data,
  admin,
  onRowClick,
  onCellClick
}) => {
  return (
    <div>
      <MuiTable
        headers={headers}
        handleSort={handleSort}
        sortDir={sortDir}
        sortColumn={sortColumn}
        data={data}
        admin
        onRowClick={onRowClick}
        onCellClick={onCellClick}
      />
    </div>
  );
};

OrganizationList.propTypes = {
  handleSort: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  sortDir: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

export default OrganizationList;
