import React, { useState, useEffect } from "react";

import Loader from "react-loader-spinner";
import moment from "moment";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { orderBy } from "lodash";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "react-s-alert";
import OrganizationList from "./components/organizationlist/OrganizationList";
import UsersList from "./components/userlist/UserList";
import FilterTextField from "../../components/FilterTextField";
import Button from "../../components/Button";
import Modal from "../../common/Modal/Modal";
import MuiSelect from "../../components/MuiSelect";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

import {
  getAllOrganizations,
  getAllOrgUsers,
  expireUser,
  expireOrganization,
  createLicense,
  getDashboardUsers
} from "../../util/APIUtils";
import {
  fetchMailCountsAdmin,
  fetchCalendarCountsAdmin,
  fetchBcardCountsAdmin,
  fetchCallDataAdmin,
  fetchLatestActivityAdmin
} from "../ActivityService";

const initialFilters = {
  startDate: moment()
    .subtract(1, "months")
    .format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD")
};

const initialLicense = {
  owner: "",
  seatCount: 0,
  expiresAt: moment().format("YYYY-MM-DD")
};

const AdminChart = () => {
  const [users, setUsers] = useState([]);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sortDir, setSortDir] = useState("desc");
  const [sortColumn, setSortColumn] = useState("latestUser");
  const [header, setHeader] = useState("Organization");
  const [showForm, setShowForm] = useState(false);
  const [newLicense, setNewLicense] = useState(initialLicense);
  const [filters, setFilters] = useState({
    startDate: initialFilters.startDate,
    endDate: initialFilters.endDate
  });

  useEffect(() => {
    getOrgs();
  }, []);

  const getOrgs = async () => {
    setLoading(true);
    const organizations = await getAllOrganizations();
    organizations.forEach(org => {
      org.expiredDate = org.expiredDate || "";
      const allUsersWithPlatform = org.androidUsers + org.iosUsers;
      org.androidUsers = userPrecentage(org.androidUsers, allUsersWithPlatform) || 0;
      org.iosUsers = userPrecentage(org.iosUsers, allUsersWithPlatform) || 0;
      if (org.activity) {
        const date = org.activity.substring(0, org.activity.indexOf("Z") + 1);
        const name = org.activity.substring(org.activity.indexOf(" "));
        org.activity = date;
        org.activityUser = name;
      } else {
        org.activity = "";
        org.activityUser = "";
      }
    });
    setOrgs(organizations);
    setLoading(false);
  };

  const userPrecentage = (percentFor, percentOf) => {
    return Math.round((percentFor / percentOf) * 100);
  };

  const handleSort = column => {
    const inverDir = {
      asc: "desc",
      desc: "asc"
    };
    setSortColumn(column);
    setSortDir(sortColumn === column ? inverDir[sortDir] : "asc");
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  const onRowClick = row => {
    if (row.appUserCount > 0) {
      setHeader("Users");
      const org = row;
      getUsersForOrg(filters.startDate, filters.endDate, org.organizationIdentifier);
      setSelectedOrg(org);
    }
  };

  const filterOrgUsers = event => {
    event.preventDefault();
    setLoading(true);
    getUsersForOrg(filters.startDate, filters.endDate, selectedOrg.organizationIdentifier);
  };

  const getUsersForOrg = async (from, to, organizationid) => {
    setLoading(true);
    const filtersIso = {
      from: moment(from)
        .startOf("day")
        .toISOString(),
      to: moment(to)
        .endOf("day")
        .toISOString()
    };
    const orgUsers = await getAllOrgUsers(organizationid);
    const fetchDashboardUsers = await getDashboardUsers(organizationid);
    const mergedData = [];
    const promises = await Promise.all([
      fetchCallDataAdmin(organizationid, filtersIso),
      fetchMailCountsAdmin(organizationid, filtersIso),
      fetchCalendarCountsAdmin(organizationid, filtersIso),
      fetchBcardCountsAdmin(organizationid, filtersIso),
      fetchLatestActivityAdmin(organizationid)
    ]);
    const userCallData = promises[0];
    const userMailData = promises[1];
    const userCalendarData = promises[2];
    const userBcardData = promises[3];
    const latestActivity = promises[4];
    orgUsers.forEach(user => {
      user.expiredDate = user.expiredDate || "";
      if (user.activity) {
        const date = user.activity.substring(0, user.activity.indexOf("Z") + 1);
        const name = user.activity.substring(user.activity.indexOf(" "));
        user.activity = date;
        user.activityUser = name;
      } else {
        user.activity = "";
        user.activityUser = "";
      }
      if (user.platform && user.appVersion) {
        user.appInfo = `${user.platform} ${user.appVersion}`;
      } else {
        user.appInfo = "";
      }
      const callCounts = makeCallCounts(
        userCallData.loggedCallCountPerUser[user.userId],
        userCallData.unloggedCallCountPerUser[user.userId]
      );

      const emailCounts = makeEmailCounts(
        userMailData.loggedMailCountPerUser[user.userId],
        userMailData.unloggedMailCountPerUser[user.userId]
      );

      const calendarCounts = makeCalendarCounts(
        userCalendarData.loggedCalendarCountPerUser[user.userId],
        userCalendarData.unloggedCalendarCountPerUser[user.userId]
      );
      const bcardCounts = makeBcardCounts(userBcardData, user);
      const lastActivity = makeLastActivity(latestActivity[user.userId]);
      const newUser = {
        ...user,
        callsLogged: callCounts.callsLogged,
        callsUnlogged: callCounts.callsUnlogged,
        mailsLogged: emailCounts.emailsLogged,
        mailsUnlogged: emailCounts.emailsUnlogged,
        calendarsLogged: calendarCounts.calendarsLogged,
        calendarsUnlogged: calendarCounts.calendarsUnlogged,
        bcardData: bcardCounts,
        lastActivity
      };
      mergedData.push(newUser);
    });
    setUsers(mergedData);
    setDashboardUsers(fetchDashboardUsers);
    setSortColumn("name");
    setLoading(false);
  };

  const makeCallCounts = (logged, unLogged) => {
    let callsLogged = {};
    let callsUnlogged = {};
    if (logged) {
      callsLogged = logged || "0";
    } else {
      callsLogged = "0";
    }
    if (unLogged) {
      callsUnlogged = unLogged || "0";
    } else {
      callsUnlogged = "0";
    }
    return { callsLogged, callsUnlogged };
  };

  const makeEmailCounts = (logged, unLogged) => {
    let emailsLogged = {};
    let emailsUnlogged = {};

    if (logged) {
      emailsLogged = logged || "0";
    } else {
      emailsLogged = "0";
    }
    if (unLogged) {
      emailsUnlogged = unLogged || "0";
    } else {
      emailsUnlogged = "0";
    }
    return { emailsLogged, emailsUnlogged };
  };

  const makeCalendarCounts = (logged, unLogged) => {
    let calendarsLogged = {};
    let calendarsUnlogged = {};

    if (logged) {
      calendarsLogged = logged || "0";
    } else {
      calendarsLogged = "0";
    }
    if (unLogged) {
      calendarsUnlogged = unLogged || "0";
    } else {
      calendarsUnlogged = "0";
    }
    return { calendarsLogged, calendarsUnlogged };
  };

  const makeBcardCounts = (bcardCounts, user) => {
    let bcardData = "";
    if (
      bcardCounts &&
      bcardCounts.createdCountPerUserByType &&
      bcardCounts.createdCountPerUserByType[user.userId]
    ) {
      Object.entries(bcardCounts.createdCountPerUserByType[user.userId]).forEach(([key, value]) => {
        bcardData += `${key}: ${value}, `;
      });
    } else {
      bcardData = "-";
    }
    return bcardData;
  };

  const goBack = () => {
    setHeader("Organizations");
    setUsers([]);
    setFilters({
      ...filters,
      startDate: initialFilters.startDate,
      endDate: initialFilters.endDate
    });
    setSortColumn("latestUser");
  };

  const makeLastActivity = latestActivity => {
    let lastActivity = {};
    if (latestActivity) {
      lastActivity = latestActivity;
    } else {
      lastActivity = "";
    }
    return lastActivity;
  };

  const expireAccount = async id => {
    try {
      await expireUser(id);
      setModalData({});
      setOpenModal(false);
      getUsersForOrg(filters.startDate, filters.endDate, selectedOrg.organizationIdentifier);
    } catch (e) {
      console.warn("Error expiring user", e);
    }
  };

  const expireOrg = async id => {
    try {
      await expireOrganization(id);
      setModalData({});
      setOpenModal(false);
      getOrgs();
    } catch (e) {
      console.warn("Error expiring org", e);
    }
  };

  const onCellClick = (event, row) => {
    event.stopPropagation();
    const isUser = row.hasOwnProperty("userId");
    const data = {
      id: isUser ? row.userId : row.organizationIdentifier,
      name: row.name,
      expired: row.expiredDate,
      isUser
    };
    setModalData(data);
    setOpenModal(true);
  };

  const closeModal = () => {
    setModalData({});
    setOpenModal(false);
  };

  const showLicenseForm = () => {
    setShowForm(!showForm);
  };

  const handleLicenseChange = event => {
    const { id, name, value } = event.target;
    setNewLicense({ ...newLicense, [id || name]: value });
  };

  const postLicense = async () => {
    const body = {
      seatCount: newLicense.seatCount,
      expiresAt: moment(newLicense.expiresAt).format("YYYY-MM-DD"),
      ownerUserCrmId: newLicense.owner.crmId,
      ownerDashboardUserId: newLicense.owner.userId
    };
    try {
      await createLicense(selectedOrg.organizationIdentifier, body);
      Alert.success("License created succesfully!");
    } catch (error) {
      Alert.error("License creation failed, please try again");
      console.warn("Error, ", error);
    }
    setShowForm(false);
    setNewLicense(initialLicense);
  };

  const renderLoader = () => {
    return (
      <div className="loader">
        <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
      </div>
    );
  };
  const renderLicenseBlock = () => {
    return (
      <div className={showForm ? "admin-license" : "admin-license-nondisplay"}>
        <h3>Create license:</h3>
        <FilterTextField
          label="License expire date"
          id="expiresAt"
          variant="outlined"
          onChange={handleLicenseChange}
          className="form-item"
          value={newLicense.expiresAt}
        />
        <TextField
          id="seatCount"
          label="User count"
          type="number"
          variant="outlined"
          value={newLicense.seatCount}
          onChange={handleLicenseChange}
        />
        <FormControl variant="outlined">
          <InputLabel className="form-item" id="demo-simple-select-outlined-label">
            Owner
          </InputLabel>
          <MuiSelect
            id="owner"
            data={dashboardUsers}
            value={newLicense.owner}
            onChange={handleLicenseChange}
            label="Owner"
            className="form-item"
          />
        </FormControl>
        <Button
          className={loading ? "create-button-disabled" : "create-button"}
          text="Create"
          onClick={postLicense}
          type="submit"
          disabled={loading}
        />
      </div>
    );
  };

  const renderUsers = () => {
    return (
      <div>
        <h3>Organization: {selectedOrg.name}</h3>
        <div className="header-icon" onClick={() => showLicenseForm()}>
          <AddCircleOutlineIcon />
          Create license
        </div>
        {renderLicenseBlock()}
        <UsersList
          sortDir={sortDir}
          sortColumn={sortColumn}
          handleSort={handleSort}
          onCellClick={onCellClick}
          data={orderBy(users, sortColumn, sortDir)}
          admin
        />
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <form noValidate autoComplete="off" onSubmit={filterOrgUsers}>
        <h3>Filter activity by dates</h3>
        <FilterTextField
          label="Start date"
          id="startDate"
          onChange={handleChange}
          value={filters.startDate}
        />
        <FilterTextField
          label="End date"
          id="endDate"
          onChange={handleChange}
          value={filters.endDate}
        />
        <Button
          className="form-button"
          text="Filter"
          onClick={filterOrgUsers}
          type="submit"
          disabled={loading}
        />
      </form>
    );
  };

  const renderModal = data => {
    return (
      <div>
        <div className="modal-header">
          <Button type="button" className="modal-button" text="X" onClick={closeModal} />
          <h1>Expiration</h1>
        </div>
        <div className="modal-body">
          <div className="modal-body-component">
            <h3>
              {data.expired ? "Unexpire " : "Expire "}
              {data.name}?
            </h3>
            <Button
              type="button"
              text={data.expired ? "Unexpire" : "Expire"}
              className={data.expired ? "revert-body-button" : "modal-body-button"}
              onClick={() => (data.isUser ? expireAccount(data.id) : expireOrg(data.id))}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="activitycharts-container">
      <Modal show={openModal}>{renderModal(modalData)}</Modal>
      <div className="activitycharts-content">
        <div>
          {users.length > 0 ? (
            <BackspaceIcon
              style={{ fontSize: 40, color: "#e33f85", cursor: "pointer" }}
              onClick={goBack}
            />
          ) : null}
          <h1>{header}</h1>
          {users.length > 0 ? renderFilters() : null}
          {loading ? (
            renderLoader()
          ) : (
            <div>
              {users.length > 0 ? (
                renderUsers()
              ) : (
                <div>
                  <OrganizationList
                    sortDir={sortDir}
                    sortColumn={sortColumn}
                    onRowClick={onRowClick}
                    handleSort={handleSort}
                    onCellClick={onCellClick}
                    admin
                    data={orderBy(orgs, sortColumn, sortDir)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminChart;
