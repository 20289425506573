const styles = theme => ({
  drawer: {
    width: 200,
    flexShrink: 0
  },
  drawerPaper: {
    width: 200
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    height: 100,
    boxShadow: 1
  },
  drawerOpen: {
    width: 200,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9) + 1
    }
  },
  fullList: {
    width: "auto"
  },
  icon: {
    color: "#e33f85"
  },
  hide: {
    display: "none"
  },
  iconButton: {
    color: "#333c4e"
  },
  navLink: {
    color: "black"
  },
  button: { marginLeft: "auto", marginRight: 10 }
});

export default styles;
