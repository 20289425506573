import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Alert from "react-s-alert";
import { CssBaseline, createMuiTheme, MuiThemeProvider } from "@material-ui/core/";
import NavDrawer from "../common/Drawer/Drawer";
import { ACCESS_TOKEN } from "../constants";
import PrivateRoute from "../common/PrivateRoute";
import Routes from "../common/Routes/routes";
import { getCurrentUser, getUsersGroups } from "../util/APIUtils";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./App.css";

const color = "#e33f85";
const theme = createMuiTheme({
  palette: {
    common: { black: color, white: color },
    primary: { main: color, dark: color, light: color },
    text: { primary: "#333c4e", secondary: color }
  }
});

class App extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      authenticated: !!localStorage.getItem(ACCESS_TOKEN),
      drawerOpen: false,
      currentUser: null,
      loading: true,
      selectedGroup: {},
      userGroups: []
    };
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
  }

  loadCurrentlyLoggedInUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      const userGroups = await getUsersGroups();
      const orgGroup = {
        id: "",
        name: "Organization"
      };
      userGroups.push(orgGroup);
      this.setState({
        currentUser,
        authenticated: true,
        loading: false,
        userGroups,
        selectedGroup: orgGroup
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  setGroupAfterCreation = async () => {
    try {
      const userGroups = await getUsersGroups();
      const orgGroup = {
        id: "",
        name: "Organization"
      };
      userGroups.push(orgGroup);
      this.setState({
        userGroups,
        selectedGroup: userGroups[0]
      });
    } catch (error) {
      console.warn("Error:", error);
    }
  };

  handleGroupChange(event) {
    this.setState({
      selectedGroup: event.target.value
    });
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false,
      currentUser: null
    });
    Alert.success("You're safely logged out!");
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  };

  closeDrawer = () => {
    this.setState({
      drawerOpen: false
    });
  };

  render() {
    const {
      currentUser,
      loading,
      drawerOpen,
      userGroups,
      selectedGroup,
      authenticated
    } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <NavDrawer
            closeDrawer={this.closeDrawer}
            isOpen={drawerOpen}
            user={currentUser}
            toggleDrawer={this.toggleDrawer}
            handleLogout={this.handleLogout}
            authenticated={authenticated}
            userGroups={userGroups}
            selectedGroup={selectedGroup}
            handleGroupChange={this.handleGroupChange}>
            <div className={drawerOpen ? "App-nav-open" : "App"}>
              <Switch>
                {Routes.map((route, index) => {
                  return route.private ? (
                    <PrivateRoute
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      authenticated={authenticated}
                      currentUser={currentUser}
                      selectedGroup={selectedGroup}
                      loadUserAndGroups={this.setGroupAfterCreation}
                    />
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      authenticated={authenticated}
                      render={props => (
                        <route.component
                          authenticated={authenticated}
                          loading={loading}
                          {...props}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
            </div>
            <Alert
              stack={{ limit: 3 }}
              timeout={3000}
              position="top-right"
              effect="slide"
              offset={65}
            />
          </NavDrawer>
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
