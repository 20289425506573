import React from "react";
import PropTypes from "prop-types";
import MuiTable from "../../../../components/Table/Table";
import headers from "./UserListHeaders";

const UsersList = ({ handleSort, sortDir, sortColumn, onCellClick, data, admin }) => {
  return (
    <div>
      <MuiTable
        headers={headers}
        handleSort={handleSort}
        sortDir={sortDir}
        sortColumn={sortColumn}
        onCellClick={onCellClick}
        data={data}
        admin
      />
    </div>
  );
};

UsersList.propTypes = {
  handleSort: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  sortDir: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

export default UsersList;
