import React, { Component } from "react";
import "../Charts.css";
import moment from "moment";
import Loader from "react-loader-spinner";
import { orderBy } from "lodash";
import FilterTextField from "../../components/FilterTextField";
import Button from "../../components/Button";
import { getOrgUserList } from "../../util/APIUtils.js";
import MuiTable from "../../components/Table/Table";
import headers from "./ActivityHeaders";
import {
  fetchMailCounts,
  fetchCalendarCounts,
  fetchBcardCounts,
  fetchCallData
} from "../ActivityService.js";

class ActivityChart extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.loadUsersAndData = this.loadUsersAndData.bind(this);
    this.loadCallData = this.loadCallData.bind(this);
    this.loadMailCounts = this.loadMailCounts.bind(this);
    this.loadCalendarCounts = this.loadCalendarCounts.bind(this);
    this.loadBcardCounts = this.loadBcardCounts.bind(this);
    this.state = {
      users: [],
      startDate: moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: true,
      sortDir: "asc",
      sortColumn: "name",
      selectedGroup: this.props.selectedGroup
    };
  }

  componentDidMount() {
    const { startDate, endDate, selectedGroup } = this.state;
    this.loadUsersAndData(startDate, endDate, selectedGroup.groupId);
  }

  componentDidUpdate = async prevProps => {
    if (prevProps.selectedGroup !== this.props.selectedGroup) {
      await this.setState({
        selectedGroup: this.props.selectedGroup,
        loading: true
      });
      this.loadUsersAndData(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedGroup.groupId
      );
    }
  };

  loadUsersAndData = async (from, to, groupId) => {
    const filters = {
      from: moment(from)
        .startOf("day")
        .toISOString(),
      to: moment(to)
        .endOf("day")
        .toISOString()
    };
    const users = await getOrgUserList(groupId);
    const filterExpired = users.filter(user => !user.expiredDate);
    this.setState({ users: filterExpired });
    this.loadCallData(filters, groupId);
    this.loadMailCounts(filters, groupId);
    this.loadCalendarCounts(filters, groupId);
    this.loadBcardCounts(filters, groupId);
    this.setState({
      loading: false
    });
  };

  loadMailCounts = async (filters, groupId) => {
    const { users } = this.state;
    const newUsers = [];
    try {
      const mailCounts = await fetchMailCounts(false, filters, groupId);
      for (const user of users) {
        if (mailCounts && mailCounts.loggedMailCountPerUser) {
          user.mailsLogged = mailCounts.loggedMailCountPerUser[user.userId]
            ? mailCounts.loggedMailCountPerUser[user.userId]
            : "0";
        } else {
          user.mailsLogged = "-";
        }
        if (mailCounts && mailCounts.unloggedMailCountPerUser) {
          user.mailsUnlogged = mailCounts.unloggedMailCountPerUser[user.userId]
            ? mailCounts.unloggedMailCountPerUser[user.userId]
            : "0";
        } else {
          user.mailsUnlogged = "-";
        }
        newUsers.push(user);
      }
      this.setState({ users });
    } catch (e) {
      console.warn("Error fetching call data", e);
      for (const user of users) {
        user.mailsLogged = "-";
        user.mailsUnlogged = "-";
        newUsers.push(user);
      }
    }
    this.setState({ users: newUsers });
  };

  loadCalendarCounts = async (filters, groupId) => {
    const { users } = this.state;
    const newUsers = [];
    try {
      const calendarCounts = await fetchCalendarCounts(false, filters, groupId);
      for (const user of users) {
        if (calendarCounts && calendarCounts.loggedCalendarCountPerUser) {
          user.calendarsLogged = calendarCounts.loggedCalendarCountPerUser[user.userId]
            ? calendarCounts.loggedCalendarCountPerUser[user.userId]
            : "0";
        } else {
          user.calendarsLogged = "-";
        }
        if (calendarCounts && calendarCounts.unloggedCalendarCountPerUser) {
          user.calendarsUnlogged = calendarCounts.unloggedCalendarCountPerUser[user.userId]
            ? calendarCounts.unloggedCalendarCountPerUser[user.userId]
            : "0";
        } else {
          user.calendarsUnlogged = "-";
        }
        newUsers.push(user);
      }
      this.setState({ users });
    } catch (e) {
      console.warn("Error fetching call data", e);
      for (const user of users) {
        user.calendarsLogged = "-";
        user.calendarsUnlogged = "-";
        newUsers.push(user);
      }
    }
    this.setState({ users: newUsers });
  };

  loadBcardCounts = async (filters, groupId) => {
    const { users } = this.state;
    const newUsers = [];
    try {
      const bcardCounts = await fetchBcardCounts(false, filters, groupId);
      for (const user of users) {
        if (
          bcardCounts &&
          bcardCounts.createdCountPerUserByType &&
          bcardCounts.createdCountPerUserByType[user.userId]
        ) {
          user.bcardData = "";
          Object.entries(bcardCounts.createdCountPerUserByType[user.userId]).forEach(
            ([key, value]) => {
              user.bcardData += `${key}: ${value}, `;
            }
          );
        } else {
          user.bcardData = "-";
        }
        newUsers.push(user);
      }
      this.setState({ users });
    } catch (e) {
      console.warn("Error fetching call data", e);

      for (const user of users) {
        user.bcardData = "-";
        newUsers.push(user);
      }
    }
    this.setState({ users: newUsers });
  };

  loadCallData = async (filters, groupId) => {
    const { users } = this.state;
    const newUsers = [];
    try {
      const callData = await fetchCallData(false, filters, groupId);
      for (const user of users) {
        if (callData && callData.loggedCallCountPerUser) {
          user.callsLogged = callData.loggedCallCountPerUser[user.userId]
            ? callData.loggedCallCountPerUser[user.userId]
            : "0";
        } else {
          user.callsLogged = "-";
        }
        if (callData && callData.unloggedCallCountPerUser) {
          user.callsUnlogged = callData.unloggedCallCountPerUser[user.userId]
            ? callData.unloggedCallCountPerUser[user.userId]
            : "0";
        } else {
          user.callsUnlogged = "-";
        }
        if (callData && callData.loggedCallDurationPerUser) {
          user.loggedCallDurationPerUser = callData.loggedCallDurationPerUser[user.userId]
            ? callData.loggedCallDurationPerUser[user.userId]
            : "0";
        } else {
          user.loggedCallDurationPerUser = "-";
        }
        if (callData && callData.unloggedCallDurationPerUser) {
          user.unloggedCallDurationPerUser = callData.unloggedCallDurationPerUser[user.userId]
            ? callData.unloggedCallDurationPerUser[user.userId]
            : "0";
        } else {
          user.unloggedCallDurationPerUser = "-";
        }
        if (callData && callData.enabledPerUser) {
          user.callsEnabled = callData.enabledPerUser[user.userId] ? "On" : "Off";
        } else {
          user.callsEnabled = "-";
        }
        newUsers.push(user);
      }
      this.setState({ users });
    } catch (e) {
      console.warn("Error fetching call data", e);
      for (const user of users) {
        user.callsLogged = "-";
        user.callsUnlogged = "-";
        user.callsEnabled = "-";
        newUsers.push(user);
      }
    }
    this.setState({ users: newUsers });
  };

  changeDate = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  filterResults = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    const { startDate, endDate, selectedGroup } = this.state;
    this.loadUsersAndData(startDate, endDate, selectedGroup.groupId);
  };

  handleSort = column => {
    const inverDir = {
      asc: "desc",
      desc: "asc"
    };

    this.setState({
      sortColumn: column,
      sortDir: this.state.sortColumn === column ? inverDir[this.state.sortDir] : "asc"
    });
  };

  renderLoader = () => {
    return (
      <div className="loader">
        <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
      </div>
    );
  };

  render() {
    const { users, loading, sortColumn, sortDir, selectedGroup } = this.state;
    return (
      <div className="activitycharts-container">
        <div className="activitycharts-content">
          <div>
            <h1>Activity for: {selectedGroup.name}</h1>
            <form noValidate autoComplete="off" onSubmit={this.filterResults}>
              <h3>Filter activity by dates</h3>
              <FilterTextField
                label="Start date"
                id="startDate"
                onChange={this.changeDate}
                value={this.state.startDate}
              />{" "}
              <FilterTextField
                label="End date"
                id="endDate"
                onChange={this.changeDate}
                value={this.state.endDate}
              />
              <Button
                className="form-button"
                text="Filter"
                onClick={this.filterResults}
                type="submit"
                disabled={this.state.loading}
              />
            </form>
            {loading ? (
              this.renderLoader()
            ) : (
              <MuiTable
                headers={headers}
                data={orderBy(users, sortColumn, sortDir)}
                handleSort={this.handleSort}
                sortDir={sortDir}
                sortColumn={sortColumn}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityChart;
