import React from "react";
import TextField from "@material-ui/core/TextField";
import { PropTypes } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBotton: theme.spacing(1),
    width: 250
  }
}));

const FilterTextField = ({ label, id, value, onChange, variant }) => {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      label={label}
      type="date"
      value={value}
      variant={variant}
      onChange={onChange}
      className={classes.textField}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

FilterTextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string
};

export default FilterTextField;
