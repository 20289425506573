import React, { useState, useEffect } from "react";
import "./CreateGroupStyles.css";
import { TextField } from "@material-ui/core";
import Loader from "react-loader-spinner";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Alert from "react-s-alert";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PropTypes from "prop-types";
import MuiList from "../../components/MuiList";
import Button from "../../components/Button";
import { getOrgUserList, postCreateGroup } from "../../util/APIUtils";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

const CreateGroup = ({ history, loadUserAndGroups }) => {
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputs, setInputs] = useState({
    search: "",
    groupName: ""
  });

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const fetchUsers = await getOrgUserList();
      setOrgUsers(fetchUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleInputs = event => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const addUserToGroup = user => {
    const copyStateArray = [...selectedUsers];
    copyStateArray.push(user);
    setSelectedUsers(copyStateArray);
  };

  const createUserGroup = async () => {
    setLoading(true);
    const userIds = [];
    selectedUsers.forEach(user => {
      userIds.push(user.userId);
    });
    const group = {
      name: inputs.groupName,
      users: userIds
    };
    try {
      await postCreateGroup(group);
      setLoading(false);
      loadUserAndGroups();
      history.push("/users");
      Alert.success("Awesome, group created succesfully!");
    } catch (error) {
      setLoading(false);
      history.push("/users");
      Alert.error("Group creation failed, please try again.");
    }
  };

  const removeUser = (user, i) => {
    const copyStateArray = [...selectedUsers];
    const searchUser = copyStateArray.indexOf(user);
    if (searchUser !== -1) {
      copyStateArray.splice(searchUser, 1);
      setSelectedUsers(copyStateArray);
    }
  };
  return (
    <div className="group-container">
      <div className="group-content">
        <div>
          <h1>Create a group</h1>
          <div className="group-selectArea">
            <h3>Select users for group</h3>
            <TextField
              id="outlined-search"
              label="Search..."
              type="search"
              name="search"
              variant="outlined"
              value={inputs.search}
              onChange={handleInputs}
              style={{ paddingBottom: 5, width: 300, color: "#e33f85" }}
            />
            <h3>Organization users:</h3>
            {loading ? (
              <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
            ) : (
              <MuiList
                data={orgUsers
                  .filter(user => user.name.toLowerCase().includes(inputs.search.toLowerCase()))
                  .filter(u => !selectedUsers.includes(u))}
                leftIcon={<AccountCircleIcon />}
                rightIcon={<GroupAddIcon />}
                className="listitem-icon"
                onClick={addUserToGroup}
              />
            )}
          </div>
          <div className="group-selectedArea">
            <h3>Group</h3>
            <TextField
              id="outlined-error-helper-text"
              label="Group name"
              variant="outlined"
              error={inputs.groupName === ""}
              helperText={inputs.groupName === "" ? "Group needs a name" : ""}
              name="groupName"
              value={inputs.groupName}
              onChange={handleInputs}
              style={{ paddingBottom: 5, width: 280, color: "#e33f85" }}
            />
            <Button
              text="Create"
              type="submit"
              onClick={createUserGroup}
              disabled={selectedUsers.length <= 0 || inputs.groupName === "" || loading}
              className={
                selectedUsers.length <= 0 || inputs.groupName === "" || loading
                  ? "button-disabled"
                  : "button"
              }
            />
            <h3>Selected users:</h3>
            {selectedUsers.length <= 0 ? (
              <p style={{ color: "red" }}>You need to select atleast 1 user to create a group.</p>
            ) : null}
            <MuiList
              data={selectedUsers}
              leftIcon={<AccountCircleIcon />}
              rightIcon={<HighlightOffIcon />}
              onClick={removeUser}
              className="listitem-icon"
            />
          </div>
        </div>
      </div>
      <Alert stack={{ limit: 3 }} timeout={3000} position="top-right" effect="slide" offset={75} />
    </div>
  );
};

CreateGroup.propTypes = {
  history: PropTypes.object.isRequired,
  loadUserAndGroups: PropTypes.func.isRequired
};
export default CreateGroup;
