import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

export const request = async options => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    throw new Error("No access token set.");
  }

  const headers = new Headers({
    "Content-Type": "application/json"
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append("Authorization", `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  const response = await fetch(options.url, options);
  const text = await response.text();
  if (response.status === 403) {
    localStorage.clear();
    window.location.assign("/login");
  }
  if (!response.ok) {
    return Promise.reject(text);
  }
  return text ? JSON.parse(text) : {};
};

export const getCurrentUser = async () => {
  return request({
    url: `${API_BASE_URL}/user/me`,
    method: "GET"
  });
};
export const getUsersGroups = async () => {
  return request({
    url: `${API_BASE_URL}/user/groups`,
    method: "GET"
  });
};

export const postCreateGroup = async group => {
  return request({
    url: `${API_BASE_URL}/user/groups/create`,
    method: "POST",
    body: JSON.stringify(group)
  });
};

export const getOrgUserList = async groupId => {
  return await request({
    url: `${API_BASE_URL}/user${groupId ? `?groupId=${groupId}` : ""}`,
    method: "GET"
  });
};

export const getSfUsers = async () => {
  return await request({
    url: `${API_BASE_URL}/user/salesforce`,
    method: "GET"
  });
};

export const getOrgLicenses = async () => {
  return await request({
    url: `${API_BASE_URL}/user/org/licenses`,
    method: "GET"
  });
};

export const updateLicense = async (licenseId, requestBody) => {
  return await request({
    url: `${API_BASE_URL}/user/org/licenses/${licenseId}`,
    method: "POST",
    body: JSON.stringify(requestBody)
  });
};

export const createLicense = async (orgId, requestBody) => {
  return await request({
    url: `${API_BASE_URL}/user/admin/license/create/${orgId}`,
    method: "POST",
    body: JSON.stringify(requestBody)
  });
};

export const deleteGroupById = async groupId => {
  return await request({
    url: `${API_BASE_URL}/user/groups/delete/${groupId}`,
    method: "DELETE"
  });
};

export const getAllUsersList = async () => {
  return await request({
    url: `${API_BASE_URL}/user/all`,
    method: "GET"
  });
};

export const expireOrganization = async orgId => {
  return await request({
    url: `${API_BASE_URL}/user/org/expire/${orgId}`,
    method: "POST"
  });
};

export const expireUser = async userId => {
  return await request({
    url: `${API_BASE_URL}/user/expire/${userId}`,
    method: "POST"
  });
};

export const getAllOrganizations = async () => {
  return await request({
    url: `${API_BASE_URL}/user/admin/organizations`,
    method: "GET"
  });
};

export const getAllOrgUsers = async orgId => {
  return await request({
    url: `${API_BASE_URL}/user/admin/${orgId}`,
    method: "GET"
  });
};

export const getDashboardUsers = async orgId => {
  return await request({
    url: `${API_BASE_URL}/user/admin/dashboardUsr/${orgId}`,
    method: "GET"
  });
};
