import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 700
  },
  header: {
    cursor: "pointer",
    color: "#e33f85",
    display: "flex",
    alignItems: "center"
  },
  cell: {
    align: "left",
    minWidth: 140
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(227, 63, 133, 0.2) !important"
    }
  },
  clickableCell: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(227, 63, 133, 0.2) !important"
    }
  }
});

const MuiTable = ({
  headers,
  data,
  admin,
  handleSort,
  sortDir,
  sortColumn,
  onRowClick,
  onCellClick
}) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i} className={classes.cell} onClick={() => handleSort(header.id)}>
                    <div className={classes.header}>
                      {header.label}
                      {sortColumn === header.id ? (
                        sortDir === "asc" ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    onClick={onRowClick ? () => onRowClick(row) : null}
                    className={onRowClick ? classes.row : null}>
                    {headers.map((header, j) => {
                      const value = row[header.id];
                      return (
                        <TableCell
                          key={j}
                          onClick={
                            onCellClick && header.id === "name"
                              ? event => onCellClick(event, row)
                              : null
                          }
                          style={{ whiteSpace: "pre-line" }}
                          className={
                            onCellClick && header.id === "name" ? classes.clickableCell : null
                          }>
                          {header.type === "date" && value
                            ? moment(value)
                                .local()
                                .format(admin ? "DD.MM.YYYY HH:mm:ss" : "L LTS")
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

MuiTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleSort: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  sortDir: PropTypes.string,
  sortColumn: PropTypes.string
};

export default MuiTable;
