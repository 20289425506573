import React from "react";
import LoginButton from "../../components/LoginButton";
import "./Login.css";
import { SF_PROD_URL, SF_SANDBOX_URL } from "../../constants";
import logo from "../../logo.svg";

const SalesforceLoginScreen = () => {
  return (
    <div className="login-container">
      <div className="login-content">
        <img src={logo} className="app-logo" alt="logo" />
        <h1 className="login-title">Login to SalesLab</h1>
        <div>
          <div className="login-provider">
            <LoginButton text="Production instance" color="primary" href={SF_PROD_URL} />
          </div>
          <div className="login-provider">
            <LoginButton text="Sandbox instance" color="primary" href={SF_SANDBOX_URL} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesforceLoginScreen;
