import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { PropTypes } from "prop-types";
import MuiSelect from "../../components/MuiSelect";

import logo from "../../logo.svg";
import styles from "./AppBarStyles";

const AppNavigationBar = ({
  toggleDrawer,
  classes,
  onLogout,
  authenticated,
  open,
  userGroups,
  selectedGroup,
  handleGroupChange
}) => {
  return (
    <div>
      <AppBar
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}>
        <Toolbar className={classes.toolbar}>
          {authenticated ? (
            <IconButton
              className={clsx(classes.iconButton, open && classes.hide)}
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          ) : null}
        </Toolbar>
        <img src={logo} className={classes.logo} alt="logo" />
        <h1 className={classes.text}>SalesLab</h1>
        {authenticated ? (
          <div className={classes.button}>
            <MuiSelect
              MenuProps={{
                style: { zIndex: 1401 }
              }}
              data={userGroups}
              value={selectedGroup}
              onChange={handleGroupChange}
              className={classes.select}
            />
            <Button onClick={onLogout} variant="outlined" color="secondary" text="Logout">
              Logout
            </Button>
          </div>
        ) : null}
      </AppBar>
    </div>
  );
};

AppNavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(AppNavigationBar);
