import { API_BASE_URL } from "../constants";
import { request } from "../util/APIUtils.js";

/*
await new Promise(resolve => setTimeout(() => resolve(), 7000));
return simulatedCallData;
*/

export const fetchResponseTimes = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/responsetimes${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchResponseTimesByTypes = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/responsetimes/types${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchCallData = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/calls${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchCallDataAdmin = async (orgId, filters) => {
  const params = [
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/calls/${orgId}${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchMailStatuses = async (filters, groupId) => {
  const params = [
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/mails${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchMailCounts = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/mails/counts${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchMailCountsAdmin = async (orgId, filters) => {
  const params = [
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/mails/counts/${orgId}${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchCalendarStatuses = async (filters, groupId) => {
  const params = [
    `${filters && filters.from ? `from=${filters.from}` : ""}`,
    `${filters && filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/calendars${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchCalendarCounts = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/calendars/counts${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchCalendarCountsAdmin = async (orgId, filters) => {
  const params = [
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");

  return await request({
    url: `${API_BASE_URL}/activity/calendars/counts/${orgId}${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchBcardCounts = async (all, filters, groupId) => {
  const params = [
    `${all ? "all=true" : ""}`,
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`,
    `${groupId ? `groupId=${groupId}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");
  return await request({
    url: `${API_BASE_URL}/activity/bcards${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchBcardCountsAdmin = async (orgId, filters) => {
  const params = [
    `${filters.from ? `from=${filters.from}` : ""}`,
    `${filters.to ? `to=${filters.to}` : ""}`
  ]
    .filter(el => {
      return el !== "";
    })
    .join("&");
  return await request({
    url: `${API_BASE_URL}/activity/bcards/${orgId}${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchLatestActivity = async (all, groupId) => {
  const params = [`${all ? "all=true" : ""}`, `${groupId ? `groupId=${groupId}` : ""}`]
    .filter(el => {
      return el !== "";
    })
    .join("&");
  return await request({
    url: `${API_BASE_URL}/latestActivity${params ? `?${params}` : ""}`,
    method: "GET"
  });
};

export const fetchLatestActivityAdmin = async orgId => {
  return await request({
    url: `${API_BASE_URL}/latestActivity/${orgId}`,
    method: "GET"
  });
};
