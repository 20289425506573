import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FaceIcon from "@material-ui/icons/Face";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Home from "../../home/Home";
import ActivityChart from "../../charts/activity/ActivityChart";
// import ResponseTimeChart from "../../charts/responsetimes/ResponseTimeChart";
import ResponseTimeTabs from "../../charts/responsetimes/ResponseTimeTabs";
import AdminChart from "../../charts/admin/AdminChart";
import Login from "../../login/login/Login";
import OAuthRedirectHandler from "../../login/oauth/OAuthRedirectHandler";
import LicenseManagement from "../../users/licenses/LicenseManagement";
import NotFound from "../NotFound/NotFound";
import Users from "../../users/Users";
import CreateGroup from "../../users/group/CreateGroup";

const Routes = [
  {
    exact: true,
    path: "/",
    drawerText: "Home",
    component: Home,
    private: true,
    inDrawer: true,
    icon: HomeIcon
  },
  {
    exact: true,
    path: "/charts/activity",
    drawerText: "Activity",
    component: ActivityChart,
    private: true,
    inDrawer: true,
    icon: EqualizerIcon
  },
  {
    exact: true,
    path: "/charts/responsetimes",
    drawerText: "Response Times",
    component: ResponseTimeTabs,
    private: true,
    inDrawer: true,
    icon: AccessTimeIcon
  },
  {
    exact: true,
    path: "/users",
    drawerText: "Users",
    component: Users,
    private: true,
    inDrawer: true,
    icon: FaceIcon
  },
  {
    exact: true,
    path: "/users/create",
    component: CreateGroup,
    private: true,
    inDrawer: false
  },
  {
    exact: true,
    path: "/users/licenses",
    component: LicenseManagement,
    private: true,
    inDrawer: false
  },
  {
    exact: true,
    path: "/charts/admin",
    drawerText: "Admin",
    component: AdminChart,
    private: true,
    inDrawer: true,
    icon: GroupIcon
  },
  {
    exact: false,
    private: false,
    path: "/login",
    component: Login
  },
  {
    exact: false,
    private: false,
    path: "/oauthlogin/callback",
    component: OAuthRedirectHandler
  },
  {
    exact: false,
    private: false,
    component: NotFound
  }
];

export default Routes;
