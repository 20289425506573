const headers = [
  {
    id: "name",
    label: "Name"
  },
  {
    id: "callByCallAverage",
    label: "Call back time for call (s)",
    align: "center"
  },
  {
    id: "emailByCallAverage",
    label: "Call back time for email (s)",
    align: "center"
  },
  {
    id: "callByEmailAverage",
    label: "Email response time for call (s)",
    align: "center"
  },
  {
    id: "emailByEmailAverage",
    label: "Email response time for email (s)",
    align: "center"
  }
];

export default headers;
