const styles = theme => ({
  appBar: {
    backgroundColor: "white",
    height: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    color: "#333c4e",
    borderBottomStyle: "solid",
    borderBottomColor: "#e33f85",
    position: "fixed",
    zIndex: 1400,
    [theme.breakpoints.down("sm")]: {
      zIndex: 800
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 200px)`,
      marginLeft: 200,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  toolbar: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 8
    }
  },
  hide: {
    display: "none"
  },
  iconButton: {
    color: "#333c4e",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginLeft: 5
    }
  },
  logo: {
    width: 60,
    height: 60,
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 30
    }
  },
  select: {
    width: 160,
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      width: 100
    }
  },
  button: {
    marginLeft: "auto",
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    }
  },
  text: {
    fontSize: `calc(25px + 2vmin)`,
    fontWight: 1500,
    [theme.breakpoints.down("sm")]: {
      fontSize: `calc(18px + 2vmin)`
    }
  }
});

export default styles;
