const headers = [
  {
    id: "name",
    label: "Name"
  },
  {
    id: "responseTimeCount",
    label: "Count of responses",
    align: "center"
  },
  {
    id: "responseTimeAverage",
    label: "Average response time (s)",
    align: "center"
  },
  {
    id: "responseTimeMinimum",
    label: "Minimum response time (s)",
    align: "center"
  },
  {
    id: "responseTimeMaximum",
    label: "Maximum response time (s)",
    align: "center"
  }
];

export default headers;
