import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    width: 300
  }
});

const LoginButton = ({ text, href }) => {
  const classes = useStyles();
  return (
    <Button className={classes.root} href={href}>
      {text}
    </Button>
  );
};

LoginButton.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired
};

export default LoginButton;
