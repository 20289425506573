import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";

const MuiSelect = ({ value, data, onChange, className, MenuProps, label, id }) => {
  MuiSelect.defaultProps = {
    value: "",
    className: "",
    label: "",
    MenuProps: {}
  };
  return (
    <Select
      MenuProps={MenuProps}
      className={className}
      value={value}
      label={label}
      name={id}
      onChange={event => onChange(event)}>
      {data.map((item, i) => {
        return (
          <MenuItem key={i} value={item.licenseId || item}>
            {item.licenseId || item.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};

MuiSelect.propTypes = {
  value: PropTypes.any,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  MenuProps: PropTypes.object,
  label: PropTypes.string
};

export default MuiSelect;
