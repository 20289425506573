import React, { useState, useEffect } from "react";

import { NavLink, useLocation } from "react-router-dom";

import clsx from "clsx";
import {
  Drawer,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  withStyles,
  Divider
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { PropTypes } from "prop-types";
import AppNavigationbar from "../AppBar/AppBar";
import Routes from "../Routes/routes";
import styles from "./DrawerStyles";

const NavDrawer = ({
  closeDrawer,
  classes,
  isOpen,
  user,
  toggleDrawer,
  authenticated,
  handleLogout,
  userGroups,
  selectedGroup,
  handleGroupChange,
  children
}) => {
  const [path, setPath] = useState("");

  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  const activeRoute = route => {
    return route === path;
  };

  const checkAdmin = (route, key) => {
    if (user && user.admin) {
      return (
        <div onClick={closeDrawer} key={key}>
          <Divider />
          <NavLink to={route.path} style={{ textDecoration: "none" }}>
            <MenuItem selected={activeRoute(route.path)}>
              <ListItemIcon className={classes.icon}>
                <route.icon />
              </ListItemIcon>
              <ListItemText className={classes.navLink} primary={route.drawerText} />
            </MenuItem>
          </NavLink>
        </div>
      );
    }
    return null;
  };
  return (
    <div style={{ height: "100%" }}>
      <AppNavigationbar
        authenticated={authenticated}
        toggleDrawer={toggleDrawer}
        onLogout={handleLogout}
        userGroups={userGroups}
        selectedGroup={selectedGroup}
        handleGroupChange={handleGroupChange}
        open={isOpen}
      />
      {authenticated ? (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen
            })
          }}
          open={isOpen}
          onClose={closeDrawer}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={closeDrawer}
              className={clsx(classes.iconButton, !isOpen && classes.hide)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div className={classes.fullList} role="presentation">
            <MenuList>
              {Routes.filter(route => route.inDrawer).map((route, key) => {
                return route.drawerText !== "Admin" ? (
                  <div onClick={closeDrawer} key={key}>
                    <NavLink to={route.path} style={{ textDecoration: "none" }}>
                      <MenuItem selected={activeRoute(route.path)}>
                        <ListItemIcon className={classes.icon}>
                          <route.icon />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.navLink}
                          onClick={closeDrawer}
                          primary={route.drawerText}
                        />
                      </MenuItem>
                    </NavLink>
                  </div>
                ) : (
                  checkAdmin(route, key)
                );
              })}
            </MenuList>
          </div>
        </Drawer>
      ) : null}

      <main style={{ height: "100%", paddingTop: 100 }}>{children}</main>
    </div>
  );
};

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(NavDrawer);
