import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";
import { ACCESS_TOKEN } from "../../constants";

class OAuth2RedirectHandler extends Component {
  getUrlParameter(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);

    const results = regex.exec(this.props.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  render() {
    const token = this.getUrlParameter("token");
    const error = this.getUrlParameter("error");

    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location }
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: this.props.location,
            error
          }
        }}
      />
    );
  }
}

OAuth2RedirectHandler.propTypes = {
  location: PropTypes.object
};

export default OAuth2RedirectHandler;
