import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ResponseTimeChart from "./ResponseTimeChart";
import ResponseTimeTypesChart from "./ResponseTimeTypesChart";

const TabPanel = props => {
  const { children, value, index, selectedGroup, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const a11yProps = index => {
  return {
    id: `response-time-tab-${index}`,
    "aria-controls": `response-time-tabpanel-${index}`
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const ResponseTimeTabs = ({ selectedGroup }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className="activitycharts-container">
        <div className="activitycharts-content">
          <h1>Response times for {selectedGroup.name}</h1>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="Response time tab selection">
              <Tab label="Statistics" {...a11yProps(0)} />
              <Tab label="Averages for types" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {value === 0 ? (
            <ResponseTimeChart selectedGroup={selectedGroup} />
          ) : (
            <ResponseTimeTypesChart selectedGroup={selectedGroup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponseTimeTabs;
