const headers = [
  {
    id: "name",
    label: "Name"
  },
  {
    id: "platform",
    label: "Platform"
  },
  {
    id: "firstInstallation",
    label: "Installation date",
    type: "date"
  },
  {
    id: "callsEnabled",
    label: "Calls"
  },
  {
    id: "emailStatus",
    label: "Email"
  },
  {
    id: "calendarStatus",
    label: "Calendar"
  },
  {
    id: "lastActivity",
    label: "Last Activity",
    type: "date"
  }
];

export default headers;
