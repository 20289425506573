import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

function MuiList({ data, onClick, leftIcon, rightIcon, className }) {
  MuiList.defaultProps = {
    leftIcon: <div />,
    rightIcon: <div />,
    className: "",
    onClick: () => {}
  };
  return (
    <List>
      {data.map((item, i) => {
        return (
          <ListItem key={i}>
            <ListItemIcon>{leftIcon}</ListItemIcon>
            <ListItemText
              primary={item.name}
              secondary={`${item.email} ${item.installed ? "APP INSTALLED" : ""}`}
            />
            <ListItemIcon className={className} onClick={() => onClick(item)}>
              {rightIcon}
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
}

MuiList.propTypes = {
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  className: PropTypes.string
};

export default MuiList;
