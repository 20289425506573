const headers = [
  {
    id: "name",
    label: "Name"
  },
  {
    id: "appUserCount",
    label: "Users"
  },
  {
    id: "createdAt",
    label: "First install",
    type: "date"
  },
  {
    id: "latestUser",
    label: "Latest install",
    type: "date"
  },
  {
    id: "androidUsers",
    label: "Android (%)"
  },
  {
    id: "iosUsers",
    label: "iOS (%)"
  },
  {
    id: "expiredDate",
    label: "Expired",
    type: "date"
  },
  {
    id: "activity",
    label: "Activity",
    type: "date"
  },
  {
    id: "activityUser",
    label: "Activity user"
  }
];

export default headers;
