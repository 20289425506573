import React, { useState, useEffect } from "react";
import "./LicenseManagementStyles.css";
import { TextField } from "@material-ui/core";
import Loader from "react-loader-spinner";
import moment from "moment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import MuiList from "../../components/MuiList";
import Button from "../../components/Button";
import MuiSelect from "../../components/MuiSelect";
import { getOrgUserList, getSfUsers, getOrgLicenses, updateLicense } from "../../util/APIUtils";

const LicenseManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [search, setSearch] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    getUsersAndLicenses();
  }, []);

  const getUsersAndLicenses = async () => {
    try {
      const promises = await Promise.all([getSfUsers(), getOrgLicenses(), getOrgUserList()]);
      const sfUsers = promises[0];
      const orgLicenses = promises[1];
      const stUsers = promises[2];
      checkInstalledUsers(sfUsers, stUsers, orgLicenses);
      setUsers(sfUsers);
      setLicenses(orgLicenses);
      setSelectedLicense(orgLicenses[0]);
    } catch (error) {
      console.warn("Error: ", error);
    }
    setLoading(false);
  };

  const checkInstalledUsers = (sfUsers, stUsers, allLicenses) => {
    const newUsers = [];
    sfUsers.forEach(sfUser => {
      const stUser = stUsers.find(stU => stU.crmId === sfUser.userId);
      if (stUser) {
        sfUser.installed = true;
        sfUser.stUserId = stUser.userId;
      }
      sfUser.hasLicense = checkIfUserHasLicense(sfUser, allLicenses);
      newUsers.push(sfUser);
    });
    setUsers(newUsers);
  };

  const checkIfUserHasLicense = (user, allLicenses) => {
    const usersArray = [];
    allLicenses.forEach(license => {
      license.userIds.forEach(userId => {
        usersArray.push(userId);
      });
    });
    const hasLicense = usersArray.find(userId => userId === user.userId);
    if (hasLicense) {
      return true;
    }
    return false;
  };

  const checkLicenseUsers = () => {
    const foundUsers = [];
    if (selectedLicense && selectedLicense.userIds) {
      selectedLicense.userIds.forEach(id => {
        const user = users.find(u => u.userId === id);
        if (user) {
          foundUsers.push(user);
        }
      });
    }
    return foundUsers;
  };

  const removeLicense = async selectedUser => {
    setButtonDisabled(false);
    const copyLicense = { ...selectedLicense };
    const searchUser = copyLicense.userIds.indexOf(selectedUser.userId);
    if (searchUser !== -1) {
      copyLicense.userIds.splice(searchUser, 1);
      setSelectedLicense(copyLicense);
    }
  };

  const handleSearch = event => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  const addUserToLicense = async filteredUser => {
    setButtonDisabled(false);
    const copy = { ...selectedLicense };
    copy.userIds.push(filteredUser.userId);
    setSelectedLicense(copy);
  };

  const handleLicenceChange = event => {
    const license = licenses.find(lic => lic.licenseId === event.target.value);
    setSelectedLicense(license);
  };

  const update = async () => {
    const body = {
      assignedBy: "",
      userIds: selectedLicense.userIds
    };
    try {
      setLoading(true);
      await updateLicense(selectedLicense.licenseId, body);
    } catch (error) {
      console.warn("ERROR: ", error);
    }
    await getUsersAndLicenses();
    setButtonDisabled(true);
  };

  const renderUserList = () => {
    return (
      <div className="group-selectArea">
        <h3>Users</h3>
        <TextField
          id="outlined-search"
          label="Search..."
          type="search"
          name="search"
          variant="outlined"
          value={search}
          onChange={handleSearch}
          style={{ paddingBottom: 5, width: 300, color: "#e33f85" }}
        />
        <MuiList
          data={users
            .filter(user => user.name.toLowerCase().includes(search.toLowerCase()))
            .filter(u => !u.hasLicense)
            .filter(
              uNoLicense => selectedLicense && !selectedLicense.userIds.includes(uNoLicense.userId)
            )}
          leftIcon={<AccountCircleIcon />}
          rightIcon={<HowToRegIcon />}
          className={
            selectedLicense && selectedLicense.userIds.length < selectedLicense.seatCount
              ? "listitem-icon"
              : "listitem-icon-disabled"
          }
          onClick={
            selectedLicense && selectedLicense.userIds.length < selectedLicense.seatCount
              ? addUserToLicense
              : null
          }
        />
      </div>
    );
  };
  const renderLicenseInfo = () => {
    return (
      <div className="group-selectedArea">
        <div>
          <h3>License:</h3>
          {licenses.length > 1 ? (
            <div>
              <MuiSelect
                className="license-select"
                data={licenses}
                onChange={handleLicenceChange}
                value={selectedLicense && selectedLicense.licenseId}
              />
            </div>
          ) : null}

          <Button
            text="Update"
            type="submit"
            onClick={update}
            disabled={loading || buttonDisabled}
            className={loading || buttonDisabled ? "button-disabled" : "button"}
          />
          {selectedLicense ? (
            <div>
              <h3>User capacity: {selectedLicense.seatCount}</h3>
              <h3>Expiration date: {moment(selectedLicense.expiresAt).format("YYYY-MM-DD")}</h3>
            </div>
          ) : null}
          <h3>
            Users under license: {(selectedLicense && selectedLicense.userIds.length) || "0"}/
            {(selectedLicense && selectedLicense.seatCount) || "0"}
          </h3>
          <MuiList
            data={checkLicenseUsers()}
            leftIcon={<AccountCircleIcon />}
            rightIcon={<HighlightOffIcon />}
            className="listitem-icon"
            onClick={removeLicense}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="group-container">
      <div className="group-content">
        <h1>License management</h1>
        {loading ? (
          <div className="loader">
            <Loader type="ThreeDots" color="#e33f85" height={100} width={100} />
          </div>
        ) : (
          <div>
            {renderUserList()}
            {renderLicenseInfo()}
          </div>
        )}
      </div>
    </div>
  );
};

export default LicenseManagement;
